// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$kandy-primary: mat.define-palette(mat.$indigo-palette);
$kandy-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$kandy-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$kandy-theme: mat.define-light-theme(
  (
    color: (
      primary: $kandy-primary,
      accent: $kandy-accent,
      warn: $kandy-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($kandy-theme);

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Source Sans 3';
    src:
      url('/assets/fonts/SourceSans3-Regular.ttf') format('truetype'),
      url('/assets/fonts/SourceSans3-Bold.ttf') format('truetype'),
      url('/assets/fonts/SourceSans3-SemiBold.ttf') format('truetype'),
      url('/assets/fonts/SourceSans3-Light.ttf') format('truetype'),
      url('/assets/fonts/SourceSans3-Medium.ttf') format('truetype'),
      url('/assets/fonts/SourceSans3-ExtraLight.ttf') format('truetype');
  }
}

@import '@angular/cdk/overlay-prebuilt.css';

html,
body {
  height: 100%;
}

body {
  margin: 0;
}
